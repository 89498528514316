/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';
import { colorsV2 } from 'style/colors-v2';
import { withHomePageSection } from 'components/withHomePageSection';

export const Container = styled.div`
  display: grid;
  gap: 24px;
  .title {
    margin-bottom: 12px;
  }
  img {
    border-radius: 8px;
  }
  ${fromScreen(776)} {
    .title {
      margin-bottom: 24px;
    }
    .info {
      order: ${props => props.reverse && -1};
    }
    .desc {
      font-size: 16px;
      line-height: 24px;
    }
    grid-template-columns: ${props =>
      props.reverse ? '1fr 280px' : '280px 1fr'};
    align-items: center;
  }

  ${fromScreen(1144)} {
    grid-template-columns: ${props =>
      props.reverse ? '1fr 520px' : '520px 1fr'};
    gap: 40px;
  }
`;

export const ExtraInfo = withHomePageSection(
  ({ title, imageHighlight, description, reverse }) => {
    return (
      <Container reverse={reverse}>
        <LazyImage handle={imageHighlight} alt={title} />
        <div className="info">
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            desktopVariant="bold/40-48"
            className="title"
            v3
            as="h2"
          >
            {title}
          </Typography>
          <Typography
            variant="regular/14-20"
            dangerouslySetInnerHTML={{
              __html: description
            }}
            color={colorsV2.black80}
            className="desc"
          />
        </div>
      </Container>
    );
  }
);
