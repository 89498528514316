import * as React from 'react';

import { colorsV2 } from 'style/colors-v2';
import { withHomePageSection } from 'components/withHomePageSection';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';
import { shadows } from 'style/shadows';
import ProgressiveImage from 'react-progressive-image';
import { getAssetLinkByHandle } from 'utils/getAssetLink';

const Main = styled.div`
  padding: 32px 0;
  .content {
    display: grid;
    gap: 24px;
    place-items: center;
    text-align: center;
    .info {
      .name-section {
        display: grid;
        gap: 8px;
      }
      .linear-badge {
        order: 1;
        width: fit-content;
        background: ${colorsV2.white100};
        padding: 4px 12px;
        border-radius: 20px;
        display: grid;
        gap: 4px;
        grid-template-columns: 20px 1fr;
        align-items: center;
        box-shadow: ${shadows.zIndex1};
        margin: 0 auto;
      }
      .author-quote {
        img {
          width: 20px;
        }
        margin-top: 32px;
        > p {
          margin-top: 8px;
        }
      }
    }

    .banner {
      width: 255px;
      .banner-image {
        width: 100%;
      }
      position: relative;
      .icon {
        position: absolute;
        &.top {
          top: 0;
          right: 0;
        }
      }
    }
  }

  ${fromScreen(458)} {
    &&& {
      .author-quote {
        img {
          width: 24px;
        }
      }
    }
  }

  ${fromScreen(776)} {
    padding: 100px 0;
    .content {
      place-items: unset;
      text-align: left;
      gap: 20px;
      grid-template-columns: 437px 1fr;
      align-items: center;
      .info {
        order: -1;
        .name-section {
          gap: 12px;
        }
        .linear-badge {
          order: -1;
          margin: unset;
        }
        .author-quote {
          img {
            width: 28px;
          }
          > p {
            font-size: 24px;
            line-height: 32px;
            font-weight: 500;
          }
        }
        .author-name {
          font-size: 40px;
          line-height: 48px;
        }
      }
      .sub-text {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  ${fromScreen(1144)} {
    .content {
      grid-template-columns: 576px 1fr;
      gap: 68px;
      .info {
        .author-quote {
          img {
            width: unset;
          }
          margin-top: 60px;
          > p {
            font-size: 32px;
            line-height: 40px;
          }
        }
        .sub-text {
          font-size: 24px;
          line-height: 32px;
        }
        .author-name {
          font-size: 48px;
          line-height: 60px;
        }
      }
      .banner {
        width: auto;
      }
    }
  }
`;

const HeaderBase = () => {
  return (
    <Main>
      <div className="content">
        <div className="banner">
          <ProgressiveImage
            src={getAssetLinkByHandle({ handle: 'efULwHLdR6qvt1WnTZsx' })}
            placeholder={getAssetLinkByHandle({
              handle: 'efULwHLdR6qvt1WnTZsx',
              width: 10
            })}
          >
            {src => (
              <img src={src} className="banner-image" alt="CEO DOL ENGLISH" />
            )}
          </ProgressiveImage>
          <LazyImage
            handle="q9llX0T1fssAvTNNkLgH"
            className="icon top"
            alt="icon-star"
          />
        </div>

        <div className="info">
          <div className="name-section">
            <div className="linear-badge">
              <LazyImage handle="ww4nKqwCRp6UIHCk2DOe" alt="founder-logo" />
              <Typography
                variant="medium/12-20"
                tabletVariant="medium/16-24"
                color={colorsV2.primary100}
                v3
              >
                Founder hệ phương pháp Linearthinking
              </Typography>
            </div>
            <Typography
              variant="bold/24-32"
              tabletVariant="bold/32-40"
              className="author-name"
              v3
              as="h1"
            >
              Lê Đình Lực
            </Typography>
            <Typography
              variant="regular/14-20"
              color={colorsV2.black60}
              className="sub-text"
            >
              Founder & CEO tại DOL IELTS Đình Lực
            </Typography>
          </div>
          <div className="author-quote">
            <LazyImage handle="hNRD9aRg6ZXFCPW2n7ig" alt="quote-icon" />
            <Typography
              variant="medium/16-20"
              tabletVariant="medium/20-28"
              v3
              color={colorsV2.black80}
            >
              Học sinh học không giỏi không phải là lỗi của học sinh, mà là lỗi
              của giáo viên và phương pháp dạy.
            </Typography>
          </div>
        </div>
      </div>
    </Main>
  );
};

export const Header = withHomePageSection(HeaderBase, {
  style: {
    backgroundColor: colorsV2.paper
  }
});
