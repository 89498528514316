import * as React from 'react';
import styled from 'styled-components';

import { fromScreen } from 'utils/media-query/responsive.util';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { Header } from 'shared/Author/Header';
import { Highlight } from 'shared/Author/Highlight';
import { AuthorStyle } from 'shared/Author/Intro';
import { NewsAboutAuthor } from 'shared/Author/NewsAboutAuthor';
import { ExtraInfo } from 'shared/Author/ExtraInfo';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';

const Space = styled.div`
  &&& {
    > section {
      padding: 32px 0;
      ${fromScreen(776)} {
        padding: 100px 0;
      }
    }
  }
`;

const AuthorPage = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        slug={location.pathname}
        h1="Lê Đình Lực"
        title="Trung Tâm Anh ngữ hàng đầu HCM - DOL IELTS Đình Lực"
        hidePostfix
        description="DOL English - trung tâm anh ngữ hàng đầu tại Tp HCM với phương pháp giảng dạy tiếng Anh độc quyền, hiệu quả vượt trội được công nhận bởi hàng ngàn học viên"
      />
      <FixedTopBarScholarshipScheduleNOffline />
      <Header />
      <Highlight />
      <Space>
        <AuthorStyle />
        <NewsAboutAuthor />
        <ExtraInfo
          title="Ứng dụng tư duy toán vào học tiếng Anh"
          description="Bằng những nỗ lực cùng tư duy logic của 1 học sinh chuyên toán, anh đã sáng tạo ra phương pháp học mới có tên gọi là Linearthinking. Anh chia sẻ: “Phương pháp của tôi tương tự như 1 công thức toán học với khả năng ứng dụng cho nhiều trường hợp, dựa trên nguyên tắc phân tích, loại bỏ thông tin thừa, tìm ra sự kết nối giữa các vấn đề. Tôi cũng áp dụng thêm 1 số nguyên tắc siêu trí nhớ vào việc học từ vựng”."
          imageHighlight="CLiCmLeRiaUEeTN9YLpJ"
        />
        <ExtraInfo
          title="Sáng tạo công nghệ để khác biệt"
          description="Anh Lực đã sớm phát triển hệ thống công nghệ để học viên có thể học, làm đề thi, rèn luyện tiếng Anh. Việc áp dụng phương pháp Linearthinking & công nghệ hiện đại cùng dàn giáo viên có chuyên môn, tận tâm với nghề chính là “bí mật thành công” giúp hàng trăm DOLBIES đạt điểm 7.0+. Đó cũng là minh chứng cho tầm nhìn đúng đắn: luôn chú trọng phát triển công nghệ của anh Lực trong nhiều năm qua. "
          imageHighlight="ZQFbLImGRCC6R0gaI0ym"
          reverse
        />
      </Space>
    </MainLayout>
  );
};

export default React.memo(AuthorPage);
