import { colorsV2 } from './colors-v2';

export const shadows = {
  // Z-index
  zIndex1: `0px 2px 2px ${colorsV2.blackShadow5}`,
  zIndex2: `
    0 4px 8px ${colorsV2.blackShadow4},
    0 0 2px ${colorsV2.blackShadow6},
    0 0 1px ${colorsV2.blackShadow4}`,
  zIndex3: `
    0 10px 20px ${colorsV2.blackShadow4},
    0 2px 6px ${colorsV2.blackShadow4},
    0 0 1px ${colorsV2.blackShadow4}`,
  zIndex4: `
    0 16px 24px ${colorsV2.blackShadow6},
    0 2px 6px ${colorsV2.blackShadow4},
    0 0 1px ${colorsV2.blackShadow4}`,
  zIndex5: `
    0 24px 32px ${colorsV2.blackShadow4},
    0 16px 24px ${colorsV2.blackShadow4},
    0 4px 8px ${colorsV2.blackShadow4},
    0 0 1px ${colorsV2.blackShadow4}`,

  // Inner Shadow
  innerBottom: `inset 0 -1px 0 ${colorsV2.gray10}`,
  innerTop: `inset 0 1px 0 ${colorsV2.gray10}`,

  boxHover: `0px 15px 40px rgba(28, 28, 33, 0.16)`
};
