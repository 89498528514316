import * as React from 'react';

import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import MemoIconDoubleQuoteOpen from 'shared/Linearthinking/icons/IconDoubleQuoteOpen';
import { fromScreen } from 'utils/media-query/responsive.util';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { withHomePageSection } from 'components/withHomePageSection';

const Main = styled.div`
  display: grid;
  gap: 24px;
  .card {
    position: relative;
    padding: 24px;
    border: 1px solid ${colorsV2.yellow120};
    border-radius: 12px;
    .content {
      margin-bottom: 16px;
    }

    svg {
      width: 32px;
      height: 26px;
      opacity: 0.7;
    }
    .logo {
      text-align: center;
      > img {
        height: 24px;
      }
    }
  }

  ${fromScreen(776)} {
    grid-template-columns: 360px 1fr;
    column-gap: 44px;
    row-gap: 24px;
    justify-content: center;
    .card {
      padding: 32px;
      svg {
        width: 56px;
        height: 45px;
      }
      .content {
        font-size: 16px;
        line-height: 24px;
      }
      &:first-child {
        height: fit-content;
      }
      &:nth-child(2) {
        margin-top: 90px;
      }
      &:last-child {
        width: 444px;
        grid-column-start: 1;
        grid-column-end: 3;
        margin: 0 auto;
      }
    }
  }
  ${fromScreen(1144)} {
    column-gap: 60px;
    row-gap: 40px;
    grid-template-columns: 532px 1fr;
    .card {
      &:last-child {
        width: 500px;
      }
    }
  }
`;

const IconWrapper = styled.div`
  background-color: ${colorsV2.paper};
  position: absolute;
  top: -14px;
  left: -10px;
  z-index: 25;
  padding: 0 8px 8px 0;

  ${fromScreen(776)} {
    top: -23px;
    left: -20px;
  }
`;

const news = [
  {
    content:
      'Tốt nghiệp hệ chuyên toán Trường Phổ thông Năng khiếu, Trường Đại học Quốc gia Thành phố Hồ Chí Minh, nhưng Lê Đình Lực lại quyết định trở thành một giáo viên tiếng Anh. Trên con đường khởi nghiệp, chàng trai 9x đã được Cục Sở hữu trí tuệ cấp chứng nhận tác quyền đối với 1 phương pháp dạy và học tiếng Anh đặc biệt.',
    logoHandle: 'NxefGrATqCgLcIfu4rmS',
    name: 'Báo Nhân dân'
  },
  {
    content:
      'Những lý thuyết toán học như rút gọn phân số, phép toán vector, phép tính lũy thừa… đều liên quan phương pháp dạy và học tiếng Anh do một người từng đứng chót lớp môn học này sáng tạo ra.',
    logoHandle: 'P0hpVDPOQtu6ex9DDB7f',
    name: 'Báo Thanh Niên'
  },
  {
    content:
      'Khởi nghiệp ở độ tuổi 22, sau 7 năm bạn Lê Đình Lực cho biết bài học quý giá nhất bản thân nhận được là cách trở thành lãnh đạo, song song là việc tận dụng công nghệ để giải quyết vấn đề gốc rễ của giáo dục.',
    logoHandle: 'PxJdaLupQBKILxK7jjCd',
    name: 'Báo Tuổi Trẻ'
  }
];

export const NewsAboutAuthor = withHomePageSection(
  () => {
    return (
      <Main>
        {news.map(({ content, logoHandle, name }) => (
          <div key={logoHandle} className="card">
            <IconWrapper>
              <MemoIconDoubleQuoteOpen color={colorsV2.yellow120} />
            </IconWrapper>
            <Typography variant="regular/14-20" className="content">
              {content}
            </Typography>
            <LazyImage handle={logoHandle} alt={name} className="logo" />
          </div>
        ))}
      </Main>
    );
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  }
);
