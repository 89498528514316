import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import { withHomePageSection } from 'components/withHomePageSection';

const Main = styled.div`
  margin: 0 auto;

  .content {
    margin-top: 24px;
  }

  ${fromScreen(776)} {
    .title {
      text-align: center;
    }
    .content {
      font-size: 16px;
      line-height: 24px;
    }
  }

  ${fromScreen(1144)} {
    max-width: 712px;
  }
`;

export const AuthorStyle = withHomePageSection(() => {
  return (
    <Main>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        className="title"
        as="h2"
      >
        Giới thiệu
      </Typography>
      <Typography variant="regular/14-20" className="content">
        Bắt đầu từ một lớp học nhỏ ở chung cư Đào Duy Từ, anh Lê Đình Lực cùng
        đội ngũ đã từng bước xây dựng phương pháp học riêng, phát triển nền tảng
        công nghệ riêng và đào tạo thành công hàng ngàn học viên với điểm số cao
        chót vót.
        <br />
        Ít ai biết rằng Founder của DOL English - chàng trai tài năng trưởng
        thành từ lớp chuyên Toán Trường Phổ thông Năng khiếu - ĐHQG TPHCM cũng
        đã từng có thời gian ám ảnh, lo lắng với vốn Tiếng Anh của mình. Sinh ra
        và lớn lên ở Gia Lai, đến năm lớp 10, anh Lực bắt đầu vào TPHCM học sau
        khi trúng tuyển lớp chuyên toán Trường Phổ thông Năng khiếu- ĐHQG TPHCM.
        Nhớ lại, khoảng thời gian đầy khó khăn này, anh Lực cho biết: “Tôi bắt
        đầu “nếm mùi” điểm liệt môn tiếng Anh. Trong khi đó, có bạn cùng lớp đã
        sở hữu chứng chỉ IELTS 8.0 từ năm lớp 9. Tôi đã thật sự bị ám ảnh bởi
        suy nghĩ sẽ đánh mất danh hiệu học sinh giỏi, rồi gia đình sẽ vô cùng
        thất vọng”.
      </Typography>
    </Main>
  );
});
